<template>
  <div class="DarkWhite">
    <v-data-table
      :headers="headers"
      :items="icsList"
      :options.sync="options"
      :server-items-length="totalCount"
      :loading="loading"
      :footer-props="footerProps"
      class="elevation-1 pt-2"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-text-field
            style="max-width: 300px"
            v-model="searchText"
            append-icon="mdi-magnify"
            placeholder="Search ICS number"
            color="green darken-2"
            class="mr-2 mt-2"
            dense
            hide-details
          ></v-text-field>
        </v-toolbar>
      </template>
      <template v-slot:[`item.FundSource`]="{ item }">
        {{ item.FundCode }} - {{ item.SpecificFundSourceDescription }}
      </template>
      <template v-slot:[`item.Amount`]="{ item }">
        <span class="text-no-wrap">₱ {{ formatPrice(item.totalAmount) }}</span>
      </template>
      <template v-slot:[`item.DateCreated`]="{ item }">
        {{ formatDate(item.DateCreated) }}
      </template>
      <template v-slot:[`item.DispenseTypeID`]="{ item }">
        <span v-if="item.DispenseTypeID == 1">Distributed</span>
        <span v-if="item.DispenseTypeID == 2">Shared</span>
      </template>
      <template v-slot:[`item.Actions`]="{ item }">
        <span class="text-no-wrap">
          <v-btn
            color="blue darken-2"
            class="mr-2"
            dark
            outlined
            elevation="2"
            x-small
            @click="viewICS(item)"
          >
            View
          </v-btn>
          <!-- <v-btn
            color="red darken-2"
            dark
            outlined
            elevation="2"
            x-small
            @click="printICS(item)"
          >
            Print
          </v-btn> -->
        </span>
      </template>
    </v-data-table>
    <ICSModal :icsData="icsData" />
    <fade-away-message-component
      displayType="variation2"
      v-model="fadeAwayMessage.show"
      :message="fadeAwayMessage.message"
      :header="fadeAwayMessage.header"
      :top="fadeAwayMessage.top"
      :type="fadeAwayMessage.type"
    ></fade-away-message-component>
  </div>
</template>

<script>
export default {
  components: {
    ICSModal: () => import("./ViewICS.vue"),
  },
  props: {
    fiscalYear: null,
  },
  data: () => ({
    searchText: "",
    searchTimeout: null,
    footerProps: { "items-per-page-options": [5, 10, 20, 50, 100, 500] },
    totalCount: 0,
    loading: true,
    options: {},
    fadeAwayMessage: {
      show: false,
      type: "success",
      header: "Successfully Saved!",
      message: "",
      top: 10,
    },
    headers: [
      {
          text: 'ICS CODE',
          align: 'start',
          sortable: false,
          value: 'ICSCODE',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'IAR CODE',
          align: 'start',
          sortable: false,
          value: 'IARCODE',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'RIS CODE',
          align: 'start',
          sortable: false,
          value: 'RISCODE',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'Purpose',
          align: 'start',
          sortable: false,
          value: 'ICSPurpose',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'Fund Source',
          align: 'start',
          sortable: false,
          value: 'FundSource',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'Amount',
          align: 'start',
          sortable: false,
          value: 'Amount',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'Date Created',
          align: 'start',
          sortable: false,
          value: 'DateCreated',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: '',
          align: ' d-none',
          sortable: false,
          value: 'other_actions',
          width: 180,
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'Dispense Type',
          align: 'start',
          sortable: false,
          value: 'DispenseTypeID',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        { text: 'Actions', value: 'Actions', align: 'center', sortable: false, class: "green darken-2 white--text text-caption font-weight-bold" },
      ],
      isLoading: false,
      icsList: [],
      icsData: [],
      renewData: [],
      transferData: [],
  }),
  watch: {
    searchText: function () {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.initialize();
      }, 600);
    },
    options: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
    fiscalYear: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
  },
  mounted() {
    this.eventHub.$on("closeViewICS", () => {
      this.initialize();
    });
  },
  beforeDestroy() {
    this.eventHub.$off("closeViewICS");
  },
  methods: {
    viewICS(item) {
      this.icsData = item;
    },
    printICS(item) {
      this.link = this.apiUrl + "/ics/report/" + item.ICSID;
      window.open(this.link);
    },
    renew(item) {
      this.renewData = item;
    },
    transfer(item) {
      this.transferData = item;
    },
    initialize() {
      this.loading = true;
      if(this.fiscalYear != null){
      let data = new FormData();
      if (this.options.page) {
        data.append("page", this.options.page);
        data.append("itemsPerPage", this.options.itemsPerPage);
      } else {
        data.append("page", 1);
        data.append("itemsPerPage", 10);
      }
      data.append("tab", this.tab);
      data.append("searchText", this.searchText);
      data.append("FiscalYearID", this.fiscalYear);
      this.axiosCall("/getICSListPerPA", "POST", data).then((res) => {
        this.loading = false;
        this.icsList = res.data.data.result;
        this.totalCount = res.data.data.total;
        if (this.totalCount <= 10) {
          this.options.page = 1;
        }
      });
      }
    },
  },
};
</script>